import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    width: '100%',
    maxWidth: '800px', // Ajusta el ancho máximo del contenido
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
}));

const PrivacyLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default PrivacyLayout;
