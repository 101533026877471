import React from 'react';
import {
  useMediaQuery,
  Paper,
  Box,
  Slide,
  Grow,
  Typography,
  Container,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';
import bg1 from '../resources/images/fondo.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  paper: {
    display: 'flex',
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed', // Añade esto
    filter: 'saturate(150%)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 25, 0, 0),
    },
  },
  formContainer: {
    backgroundColor: '#2c95ff33',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 300ms ease, transform 300ms ease',
    '&:hover': {
      boxShadow: '0 16px 32px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.1)',
      transform: 'scale(1.02)',
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
  },
  subtitle: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '1.25rem',
    color: 'aliceblue',
    textShadow: `0 0 5px ${theme.palette.secondary.main}, 0 0 10px ${theme.palette.secondary.main}, 0 0 15px ${theme.palette.secondary.main}, 0 0 20px ${theme.palette.secondary.main}`,
    animation: '$glow 1.5s ease infinite',
  },
  '@keyframes glow': {
    '0%': {
      textShadow: `0 0 5px ${theme.palette.primary.main}, 0 0 10px ${theme.palette.primary.main}, 0 0 15px ${theme.palette.primary.main}, 0 0 20px ${theme.palette.primary.main}`,
    },
    '100%': {
      textShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 20px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}, 0 0 40px ${theme.palette.primary.main}`,
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <Grow in timeout={1000}>
        <div className={classes.sidebar}>
          {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}
        </div>
      </Grow>
      <Slide in direction="left" timeout={1000}>
        <Paper className={classes.paper}>
          <Container maxWidth="xs">
            <Typography className={classes.subtitle} variant="h5" align="center">
              Un Servicio de UNITRACK
            </Typography>
          </Container>
          <Box className={classes.formContainer}>
            <form className={classes.form}>
              {children}
            </form>
          </Box>
        </Paper>
      </Slide>
    </main>
  );
};

export default LoginLayout;
