import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  useMediaQuery, InputAdornment, InputLabel, Select, Typography, MenuItem, alpha, FormControl, Button, TextField, Snackbar, IconButton, Tooltip, LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VpnKey from '@mui/icons-material/VpnKey';
import CircularProgress from '@mui/material/CircularProgress';
import EmailIcon from '@mui/icons-material/Email';
import LoginIcon from '@mui/icons-material/Login';
import LanguageIcon from '@mui/icons-material/Language';
import PrivacyIcon from '@mui/icons-material/PrivacyTip';
import TermsIcon from '@mui/icons-material/Gavel';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import { handleLoginTokenListeners, nativeEnvironment, nativePostMessage } from '../common/components/NativeInterface';
import LogoImage from './LogoImage';
import { useCatch } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  extraContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  registerButton: {
    minWidth: 'unset',
  },
  resetPassword: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  customTextField: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiInputLabel-filled': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '& .MuiFilledInput-root': {
      borderRadius: theme.spacing(1),
      backgroundColor: '#eeeeee',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
  customInputLabel: {
    position: 'relative',
    bottom: theme.spacing(4),
    left: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  customSelect: {
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiFilledInput-root:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 0.2rem ${alpha(theme.palette.primary.main, 0.25)}`,
    },
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  linkButton: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [email, setEmail] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');

  const registrationEnabled = useSelector((state) => state.session.server.registration);
  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);
  const emailEnabled = useSelector((state) => state.session.server.emailEnabled);
  const openIdEnabled = useSelector((state) => state.session.server.openIdEnabled);
  const openIdForced = useSelector((state) => state.session.server.openIdEnabled && state.session.server.openIdForce);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const showRegisterButton = false;

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = '';
      try {
        const expiration = moment().add(6, 'months').toISOString();
        const response = await fetch('/api/session/token', {
          method: 'POST',
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = '';
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const handlePasswordLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
      });
      if (response.ok) {
        const user = await response.json();
        generateLoginToken();
        dispatch(sessionActions.updateUser(user));
        navigate('/');
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPassword('');
    } finally {
      setLoading(false);
    }
  };

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetch(`/api/session?token=${encodeURIComponent(token)}`);
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      navigate('/');
    } else {
      throw Error(await response.text());
    }
  });

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handlePasswordLogin(e);
    }
  };

  const handleOpenIdLogin = () => {
    document.location = '/api/session/openid/auth';
  };

  useEffect(() => nativePostMessage('authentication'), []);

  useEffect(() => {
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  if (openIdForced) {
    handleOpenIdLogin();
    return (<LinearProgress />);
  }

  return (
    <LoginLayout>
      <div className={classes.options}>
        {nativeEnvironment && (
          <Tooltip title={t('settingsServer')}>
            <IconButton onClick={() => navigate('/change-server')}>
              <LockOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.container}>
        {useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.primary.main} />}
        <TextField
          className={classes.customTextField}
          required
          error={failed}
          label={t('userEmail')}
          name="email"
          value={email}
          autoComplete="email"
          autoFocus={!email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={handleSpecialKey}
          helperText={failed && 'Nombre de usuario o contraseña no válidos'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon className={classes.customInputAdornment} />
              </InputAdornment>
            ),
          }}
          variant="filled"
        />
        <TextField
          className={classes.customTextField}
          required
          error={failed}
          label={t('userPassword')}
          name="password"
          value={password}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          autoFocus={!!email}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={handleSpecialKey}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKey className={classes.customInputAdornment} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.customInputAdornment}
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="filled"
        />
        <Button
          onClick={handlePasswordLogin}
          onKeyUp={handleSpecialKey}
          variant="contained"
          color="secondary"
          disabled={!email || !password || loading}
          startIcon={<LoginIcon />}
        >
          {loading ? <CircularProgress size={24} /> : t('loginLogin')}
        </Button>
        {openIdEnabled && (
          <Button
            onClick={() => handleOpenIdLogin()}
            variant="contained"
            color="secondary"
          >
            {t('loginOpenId')}
          </Button>
        )}
        <div className={classes.extraContainer}>
          {showRegisterButton ? (
            <Button
              className={classes.registerButton}
              onClick={() => navigate('/register')}
              disabled={!registrationEnabled}
              color="secondary"
            >
              {t('loginRegister')}
            </Button>
          ) : null}
          {languageEnabled && (
            <FormControl fullWidth className={classes.customSelect}>
              <InputLabel variant="outlined">{t('loginLanguage')}</InputLabel>
              <Select
                label={t('loginLanguage')}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                startAdornment={(
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                )}
              >
                {languageList.map((it) => (
                  <MenuItem key={it.code} value={it.code}>
                    {it.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {emailEnabled && (
          <Button
            onClick={() => navigate('/reset-password')}
            className={classes.resetPassword}
            underline="none"
            variant="contained"
          >
            <VpnKey />
            {t('loginReset')}
          </Button>
        )}
      </div>
      <div className={classes.extraContainer}>
        {/* ... other components */}
        <div
          onClick={() => {
            navigate('/privacy-policy');
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              navigate('/privacy-policy');
            }
          }}
          role="button"
          tabIndex={0}
          className={classes.linkButton}
        >
          <PrivacyIcon />
          <Typography variant="body2">{t('loginPrivacyPolicy')}</Typography>
        </div>
        <div
          onClick={() => {
            navigate('/terms-of-service');
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              navigate('/terms-of-service');
            }
          }}
          role="button"
          tabIndex={0}
          className={classes.linkButton}
        >
          <TermsIcon />
          <Typography variant="body2">{t('loginTermsOfService')}</Typography>
        </div>
      </div>
      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </LoginLayout>
  );
};

export default LoginPage;
