import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Container,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrivacyLayout from './PrivacyLayout';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
  },
  content: {
    maxHeight: '75vh',
    overflowY: 'auto',
  },
  paragraph: {
    marginBottom: theme.spacing(1.5),
    textAlign: 'justify',
    lineHeight: '1.6',
  },
}));

const PrivacyPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();

  return (
    <PrivacyLayout>
      <AppBar position="fixed" color="transparent" elevation={0} className={classes.appBar}>
        <Toolbar>
          <IconButton color="primary" onClick={() => navigate('/login')}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} color="primary">
            {t('privacyPolicyTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box marginTop={10}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.content}>
                <Typography variant="h5" className={classes.paragraph}>
                  {t('privacyPolicyHeader1')}
                </Typography>
                <Typography variant="subtitle1" className={classes.paragraph}>
                  {t('privacyPolicyHeader2')}
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  {t('privacyPolicyHeader3')}
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  {t('privacyPolicyHeader4')}
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  {t('privacyPolicyHeader5')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent1')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent2')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent3')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent4')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent5')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent6')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent7')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent8')}
                </Typography>
                <Typography variant="body1" paragraph className={classes.paragraph}>
                  {t('privacyPolicyContent9')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </PrivacyLayout>
  );
};

export default PrivacyPage;
